import React from 'react'
import Error from 'next/error'
import Layout from '../components/layout'
import Header from '../components/cms/header'
import Footer from '../components/cms/footer'
import { getMenu, Menu } from '../utils/menus'
import { NextPage } from 'next'
import { getSetting } from '../utils/settings'
import { getPage, Page } from '../utils/pages'
import { HTMLRenderer } from '@react-page/renderer'
import slate from '@react-page/plugins-slate'
import image from '@react-page/plugins-image'
import spacer from '@react-page/plugins-spacer'
import intro from '../components/cms/intro'
import teaser from '../components/cms/checkTeaser'
import tile from '../components/cms/tile'
import checks from '../components/cms/checks'
import ChecksHome from '../components/cms/checksHome'
import { getToken } from '../utils/auth'

interface Props {
  headerMenu: Menu[]
  footerMenu: Menu[]
  page: Page
  token?: string
}

type indexPageType = {
  id: string
  key: string
  value: {
    id: string
  }
}

const Home: NextPage<Props> = ({ headerMenu, footerMenu, page, token }) => {
  if (!headerMenu || !footerMenu) {
    return <Error statusCode={404} />
  }

  const plugins = {
    layout: [tile({ imageUpload: () => {}, categories: [] })],
    content: [image, slate(), intro, teaser, checks, spacer],
  }

  return (
    <div className="landingPage">
      <Header headerMenu={headerMenu} token={token} />
      <Layout token={token}>
        <ChecksHome token={token} />
      </Layout>
    
      <Footer headerMenu={headerMenu} footerMenu={footerMenu} />
    </div>
  )
}

Home.getInitialProps = async ({ res, req }) => {
  const handleError = (error: any) => {
    if (error && res) {
      res.statusCode = 404
    }
  }

  const headerMenu = await getMenu('header').catch(handleError)
  const footerMenu = await getMenu('footer').catch(handleError)
  const token = getToken({ req })

  return {
    // page,
    headerMenu,
    footerMenu,
    token,
  } as Props
}

export default Home
