import { IconBookmark } from '../icons'
import React, { useEffect, useState } from 'react'
import { getChecks, Check } from '../../utils/checks'
import CheckSellTable from '../check/checkSellTable'
import { PaginatedApiResponse } from '../../utils/fetch'
import CategorySelect from '../form/categorySelect'
import { Formik, Form } from 'formik'
import { getCategory } from '../../utils/categories'
import Strings from '../strings'
import { isLoggedIn } from '../../utils/auth'

const onInput = onChange => {
    return e => {
        // Dispatch the onChange action with the new value
        onChange({
            value: e.target.value,
        })
    }
}

const ChecksHome = ({ token }) => {

    const user = isLoggedIn(token)
    // console.log(user)

    const [categories, setCategories] = useState<string[]>([])
    const [loaded, setLoaded] = useState(false)
    const [checks, setChecks] = useState<PaginatedApiResponse<Check>>({
        data: [],
        totalRecords: 0,
        page: 0,
        perPage: 0,
    })

    useEffect(() => {
        getChecks(token).then(data => {
            setChecks(data)
        })
        const param = location.search.split('category=')
        if (param[0] !== '') {
            getCategory(decodeURI(location.search.split('category=')[1]))
                .then(category => {
                    setCategories([category.id])
                })
                .finally(() => {
                    setLoaded(true)
                })
        } else {
            setLoaded(true)
        }
    }, [])

    useEffect(() => {
        if (categories.length) {
            getChecks(token, checks.page, checks.perPage, { categoryIds: categories.join() }).then(data => {
                setChecks(data)
            })
        } else {
            // console.log('no cat')
            getChecks(token).then(data => {
                // console.log(data)
                setChecks(data)
            })
        }
    }, [categories])

    const handleSubmit = ({ categories }) => {
        // console.log(categories)
        setCategories(categories)
    }

    // If we are not in edit mode, remove the input field
    return (
        <div className="py=6 flex wrap justify-center align-center light">
            <div className="w=site flex-grid mx relative align-start">
                <h1 className="text-align=c mb=5 w=full">{Strings.de.components.cms.checkOverview}</h1>

                <div className="w=1/4 r c=text elevated">
                    <div className="px=3 py=2 c=prim-alt bold bb">{Strings.de.components.cms.filterCategories}</div>
                    {loaded && (
                        <Formik initialValues={{ categories }} onSubmit={handleSubmit}>
                            {({ submitForm }) => (
                                <Form className="p=2" onChange={() => setTimeout(submitForm, 0)}>
                                    <CategorySelect token={token}/>
                                </Form>
                            )}
                        </Formik>
                    )}
                </div>
                <div className="w=3/4">
                    <div className="elevated">
                        {!!checks.data.length && (
                            <CheckSellTable
                                category={categories}
                                page={checks.page}
                                perPage={checks.perPage}
                                totalRecords={checks.totalRecords}
                                checks={checks.data}
                                token={token}
                            />
                        )}
                    </div>
                </div>
            </div>

            <style jsx>{`
        .light {
          background-color: #f8f9f9;
        }
        .bb {
          border-color: rgba(224, 224, 224, 1);
        }
        .py\=2 {
          padding: 16px;
          line-height: 1.5rem;
        }
      `}</style>
        </div>
    )

}

export default ChecksHome
