import React from 'react'
import Logo from '../logo'
import Link from '../link'
import { NextPage } from 'next'
import { Menu } from '../../utils/menus'
import Strings from '../strings'

interface Props {
  headerMenu: Menu[]
  footerMenu: Menu[]
}

const Footer: NextPage<Props> = ({ headerMenu, footerMenu }) => {
  return (
    <footer className="c=prim w=viewport py=5 custom">
      <div className="mx w=site flex-grid wrap align-center">
        <div className="w=1/4">
          <Logo color="currentColor" />
        </div>
        <nav className="w=1/4 flex justify-end">
          <ul>
            {footerMenu.map(({ title, slug }, index) => (
              <Link href="/[slug]" as={`/${slug}`} key={index}>
                <a className="px=1">{title}</a>
              </Link>
            ))}
          </ul>
        </nav>
        <div className="w=full text-l flex justify-center mt=3">
          Copyright © {new Date().getFullYear()} {Strings.de.title}
        </div>
      </div>
    </footer>
  )
}

export default Footer
