import { ContactsOutlined } from '@material-ui/icons'
import { useFetch, ResponseWithId, createUrl, ResponseWithSuccess, ResponseWithBool } from './fetch'

export type Category = {
  id: string
  name: string
  parentId: string | null
  isPrivate: boolean | false
  children: Category[]
}

export type CategoryUpdate = {
  id?: string
  name?: string
  parentId?: string | null
  isPrivate?: boolean
  children?: Category[]
}

const relativUrl = '/v1/categories'

export async function getCategories(id?: string): Promise<Category[]> {
  const url = createUrl(`${relativUrl}`)
  if (id) {
    url.searchParams.set('ids', id)
  }
  return useFetch({ url, method: 'GET' })
}

export async function createCategory(token: string, category: CategoryUpdate): Promise<ResponseWithId> {
  const url = createUrl(relativUrl)
  return useFetch({ token, url, method: 'POST', body: category })
}

export async function getCategory(id: string): Promise<Category> {
  const url = createUrl(`${relativUrl}`)
  url.searchParams.set('ids', id.toString())
  return useFetch({ url, method: 'GET' }).then((categories: Category[]) => categories[0])
}

export async function updateCategory(token: string, id: string, category: CategoryUpdate): Promise<ResponseWithId> {
  const url = createUrl(`${relativUrl}/${id}`)
  return useFetch({ token, url, method: 'PUT', body: category })
}

export async function deleteCategory(token: string, id: string, force = false): Promise<ResponseWithSuccess> {
  const url = createUrl(`${relativUrl}/${id}`)
  return useFetch({ token, url, method: 'DELETE', body: { force } })
}

export function getFlatCategories(): Promise<Category[]> {
  const url = createUrl(`${relativUrl}`)
  url.searchParams.set('flatten', 'true')
  return useFetch({ url, method: 'GET' })
}

export function checkAccessSub(category, checks, level = 0) {
  
  let hasChildren = category.children.length > 0
  let found_sub = false

  category.children.forEach(childcat => {
    found_sub = checks.some(c => c.categories_ids.indexOf(childcat.id) >= 0)
    if (hasChildren){
      level = level + 1
      checkAccessSub(childcat, checks, level)
    }
  });

  return found_sub
}

export function checkAccess(category, checks) {
  
  const hasChildren = category.children.length > 0
  let found_self = false
  let found_sub = false

  found_self = checks.some(c => c.categories_ids.indexOf(category.id) >= 0)

  if (hasChildren){
    found_sub = checkAccessSub(category, checks)
  }

  if (found_self || found_sub) {
    return true
  } else {
    return false
  }
  
}
