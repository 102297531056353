import React, { FunctionComponent, Fragment } from 'react'
import Head from 'next/head'

import '../styles/defaults.sass'
import Strings from './strings'

interface Props {
  nav?: boolean,
  token?: string,
}

const Layout: FunctionComponent<Props> = ({ children, nav = true, token }) => (
  <Fragment>
    <Head>
      <title>{Strings.de.title}</title>
    </Head>

    <div className="flex h=full cm--content-wrapper">
      <main className="w=site mx h=full">
        <div className="container">{children}</div>
      </main>
    </div>
  </Fragment>
)

export default Layout
